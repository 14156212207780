import { Injectable, InjectionToken, OnDestroy } from "@angular/core";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { Select, Store } from "@ngxs/store";
import { OrganizationState } from "@vp/data-access/organization";
import { ContentData, Organization, User } from "@vp/models";
import { filterNullMap } from "@vp/shared/operators";
import { AppStoreService } from "@vp/shared/store/app";
import { camelize, deepCopy } from "@vp/shared/utilities";
import { nanoid } from "nanoid";
import { createPatch } from "rfc6902";
import { EMPTY, Observable, Subject, combineLatest, of, zip } from "rxjs";
import { concatMap, map, switchMap, take, takeUntil, tap, withLatestFrom } from "rxjs/operators";
import { ContentApiService } from "../api/content-api-service";
import { ContentDataFilter } from "../models/content-data-filter";
import { ContentOperations } from "../models/content-operations.model";
import * as ContentFilterStateActions from "../state+/content-filter-state.actions";
import { ContentFilterState } from "../state+/content-filter.state";

export const CONTENT_MANAGEMENT_API_BASE_URL = new InjectionToken<string>("API_BASE_URL");

export const DEFAULT_PAGER_LIST = [5, 10, 25, 100];

export interface ContentDetails {
  contentId: string;
  contentTypeId: string;
}

@Injectable({
  providedIn: "root"
})
export class ContentManagementService implements OnDestroy {
  @Select(OrganizationState.organization) organization$!: Observable<Organization>;
  @Select(ContentFilterState.workingCopy) workingCopy$!: Observable<ContentData | null>;
  @Select(ContentFilterState.contentData) contentData$!: Observable<ContentData | null>;
  @Select(ContentFilterState.currentFilter) contentDataFilter$!: Observable<ContentDataFilter>;

  @Select(ContentFilterState.pendingOperations)
  pendingOperations$!: Observable<ContentOperations | null>;
  private readonly _destroyed$ = new Subject<void>();

  contentTypes$ = this.organization$.pipe(
    filterNullMap(),
    map(org => {
      return org.contentTypes ?? [];
    }),
    takeUntil(this._destroyed$)
  );

  queryParams$ = this.activatedRoute.queryParamMap.pipe(
    takeUntil(this._destroyed$),
    filterNullMap(),
    map(this.getStateFromParams)
  );

  contentDetails$ = this.queryParams$.pipe(
    map(paramMap => {
      return {
        contentId: paramMap.contentId,
        contentTypeId: paramMap.contentTypeId
      } as ContentDetails;
    })
  );

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly store: Store,
    private readonly contentApiService: ContentApiService,
    private readonly appStoreService: AppStoreService
  ) {
    this.queryParams$
      .pipe(
        tap(paramMap => {
          this.store.dispatch(
            new ContentFilterStateActions.SetFilter({
              contentTypeId: paramMap.contentTypeId,
              contentId: paramMap.contentId,
              search: paramMap.search,
              skip: paramMap.skip,
              take: paramMap.take
            })
          );
        })
      )
      .subscribe();
  }

  Context: Observable<ContentData> = this.contentData$.pipe(
    filterNullMap(),
    map(data => deepCopy(data))
  );

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  setWorkingCopy(workingCopy: ContentData) {
    return this.workingCopy$?.pipe(
      filterNullMap(),
      switchMap((original: ContentData) => {
        return combineLatest([
          of(original),
          of(workingCopy).pipe(
            tap(workingCopy =>
              this.store.dispatch(new ContentFilterStateActions.SetWorkingCopy(workingCopy))
            )
          )
        ]);
      })
    );
  }

  loadExistingContent(partialContentData: Partial<ContentData>): Observable<ContentData> {
    this.store.dispatch(new ContentFilterStateActions.LoadContentData(partialContentData, true));
    return this.contentDataFilter$.pipe(
      take(1),
      concatMap((contentDataFilter: ContentDataFilter) => {
        if (contentDataFilter.contentId && contentDataFilter.contentTypeId) {
          return this.getContent(contentDataFilter.contentId, contentDataFilter.contentTypeId);
        }
        return EMPTY;
      }),
      tap((workingCopy: ContentData) => {
        if (workingCopy) {
          this.store.dispatch(new ContentFilterStateActions.SetContentData(workingCopy));
          this.store.dispatch(new ContentFilterStateActions.SetWorkingCopy(workingCopy));
        }
      })
    );
  }

  updateWorkingCopy = () => {
    return combineLatest([
      this.contentData$.pipe(filterNullMap()),
      this.workingCopy$.pipe(filterNullMap())
    ]).pipe(
      map(([original, merged]) => {
        return this.getOperations(original, merged);
      }),
      tap(contentOperations => {
        this.store.dispatch(new ContentFilterStateActions.SetPendingOperations(contentOperations));
      })
    );
  };

  updateTags = (contentData: ContentData) => {
    return this.appStoreService.user$
      .pipe(
        take(1),
        withLatestFrom(this.contentData$.pipe(filterNullMap())),
        switchMap(([user, original]: [User, ContentData]) => {
          const updated = {
            ...contentData,
            createdBy: contentData.createdBy == "" ? user.userId : contentData.createdBy,
            lastUpdatedBy: user.userId,
            contentId: contentData.contentId == "" ? nanoid() : contentData.contentId,
            friendlyId: camelize(contentData.displayName),
            tags: contentData.tags
          };
          return zip(
            of(original),
            of(updated),
            this.store.dispatch(new ContentFilterStateActions.SetWorkingCopy(updated))
          );
        }),
        map(([original, updated]) => {
          return this.getOperations(original, updated);
        }),
        tap(contentOperations => {
          this.store.dispatch(
            new ContentFilterStateActions.SetPendingOperations(contentOperations)
          );
        })
      )
      .subscribe();
  };

  getQueryParams = (): Observable<ParamMap> => {
    return this.activatedRoute.queryParamMap;
  };

  getContent = (contentId: string, contentTypeId: string | null = null) => {
    return this.contentApiService.getContent(contentId, contentTypeId);
  };

  downloadContent = (contentId: string) => {
    return this.contentApiService.downloadContent(contentId);
  };

  private getOperations(original: ContentData, updated: ContentData) {
    return {
      contentId: updated.contentId,
      operations: createPatch(original, updated)
    } as ContentOperations;
  }

  createOrEditContent(partialContentData: ContentData) {
    if (!partialContentData.contentId) {
      return EMPTY;
    }
    this.store.dispatch(new ContentFilterStateActions.SetWorkingCopy(partialContentData));
    return this.getContent(partialContentData.contentId, partialContentData.contentTypeId).pipe(
      withLatestFrom(this.workingCopy$.pipe(filterNullMap())),
      map(([existingContent, workingCopy]: [ContentData, ContentData]) => {
        if (!existingContent.contentId) {
          this.store.dispatch(new ContentFilterStateActions.AddContent(workingCopy));
        } else {
          this.store.dispatch(new ContentFilterStateActions.CommitOperations());
        }
      })
    );
  }

  getContentsByCaseId(caseId: string): Observable<ContentData[]> {
    return this.contentApiService.getContentsByCaseId(caseId);
  }

  getDefaultContent(contentId: string, contentTypeId: string) {
    return {
      contentId: contentId,
      friendlyId: "",
      displayName: "",
      description: "",
      active: true,
      roles: [],
      permissionTags: [],
      content: "",
      contentTypeId: contentTypeId,
      isPublic: true,
      createdBy: "",
      file: { fileDescription: "", fileName: "", fileTextBase64: "", url: "" },
      createdDateTime: "",
      lastUpdatedDateTime: "",
      lastUpdatedBy: "",
      tags: [],
      emailSubject: "",
      path: ""
    } as ContentData;
  }

  private getStateFromParams(paramMap: ParamMap): PageParams {
    const search = paramMap.get("search") || null;
    const take = Number(paramMap.get("take"));
    const skip = Number(paramMap.get("skip"));
    let contentId = paramMap.get("contentId");
    if (contentId === null) {
      contentId = nanoid();
    }
    const contentTypeId = paramMap.get("contentTypeId") || "all";
    return {
      pageSizeOptions: DEFAULT_PAGER_LIST,
      search: search,
      take: take,
      skip: skip,
      pageIndex: skip / take,
      contentTypeId: contentTypeId,
      contentId: contentId
    } as PageParams;
  }
}

export interface PageParams {
  pageSizeOptions: number[];
  search: string;
  take: number;
  skip: number;
  pageIndex: number;
  deptId: string;
  contentTypeId: string;
  contentId: string;
}
