import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Select, Store } from "@ngxs/store";
import { CaseFilterState } from "@vp/data-access-case-filter";
import { ApplicationState, EventAggregator } from "@vp/data-access/application";
import { CaseState } from "@vp/data-access/case";
import { CaseTypesState } from "@vp/data-access/case-types";
import * as CommunicationActions from "@vp/data-access/communications";
import { CommunicationState, CommunicationsApiService } from "@vp/data-access/communications";
import { OrganizationState } from "@vp/data-access/organization";
import { UserState } from "@vp/data-access/users";
import {
  CaseData,
  CaseType,
  CommunicationData,
  CommunicationEvent,
  CommunicationOperations,
  CommunicationViewModel,
  Organization,
  OrganizationFeatures,
  RolesAllowedPerRole,
  User,
  UserRole
} from "@vp/models";
import { CaseContextService } from "@vp/shared/case-context";
import { FeatureService } from "@vp/shared/features";
import { FileApiService } from "@vp/shared/file/data-access/file-service";
import { NotificationService } from "@vp/shared/notification-service";
import { filterNullMap } from "@vp/shared/operators";
import { PermissionsConstService } from "@vp/shared/permissions-const";
import { AppStoreService } from "@vp/shared/store/app";
import { NgxPermissionsService } from "ngx-permissions";
import { ComponentType } from "ngx-toastr";
import { createPatch } from "rfc6902";
import { EMPTY, Observable, combineLatest } from "rxjs";
import { concatMap, filter, map, switchMap, take, tap, withLatestFrom } from "rxjs/operators";
@Injectable({
  providedIn: "root"
})
export class CommunicationService {
  @Select(CaseFilterState.results) filteredCaseData$!: Observable<CaseData[]>;
  @Select(CaseState.current) caseData$!: Observable<CaseData>;
  @Select(CaseTypesState.currentCaseType) caseType$!: Observable<CaseType>;
  @Select(CaseTypesState.allCaseTypes) caseTypes$!: Observable<CaseType[]>;
  @Select(OrganizationState.organization) organization$!: Observable<Organization>;
  @Select(ApplicationState.loggedInUser) loggedInUser$!: Observable<User | null>;
  @Select(UserState.currentUser) public currentUser$!: Observable<User>;
  @Select(UserState.currentUserRole) currentUserRole$!: Observable<UserRole>;

  constructor(
    private readonly notificationService: NotificationService,
    private readonly store: Store,
    private readonly caseContextService: CaseContextService,
    public readonly eventAggregator: EventAggregator,
    private readonly fileApiService: FileApiService,
    private appStore: AppStoreService,
    private readonly ngxPermissionsService: NgxPermissionsService,
    private readonly featureService: FeatureService,
    public permConst: PermissionsConstService,
    private readonly matDialog: MatDialog,
    private communicationsApiService: CommunicationsApiService
  ) {}

  loggedInUserId$: Observable<string> = this.loggedInUser$.pipe(
    filterNullMap(),
    map(user => user.userId),
    filterNullMap()
  );

  loggedInUserRoleId$: Observable<string> = this.loggedInUser$.pipe(
    filterNullMap(),
    map(user => user.selectedRoleId),
    filterNullMap()
  );

  loggedInUserRoleFriendlyId$: Observable<string> = this.loggedInUser$.pipe(
    filterNullMap(),
    map(user => {
      return user.roles.find(x => x.roleId === user.selectedRoleId)?.friendlyId ?? "";
    }),
    filterNullMap()
  );

  loggedInUserFriendlyId$: Observable<string> = this.loggedInUser$.pipe(
    filterNullMap(),
    map(user => {
      const roles = this.store.selectSnapshot(OrganizationState.organization)?.roles;
      return roles?.find(x => x.roleId === user.selectedRoleId)?.friendlyId;
    }),
    filterNullMap()
  );

  selectedRole$ = this.organization$.pipe(
    withLatestFrom(this.appStore.user$),
    map(([org, user]: [Organization, User]) =>
      org.roles.find(role => role.roleId == user.selectedRoleId)
    ),
    filterNullMap()
  );

  rolesAllowedPerRole$ = this.featureService.configurationDictionaries$("communications").pipe(
    map((config: Record<string, unknown>) => {
      return config["rolesAllowedToBeAddressedByARole"] as RolesAllowedPerRole[];
    }),
    filterNullMap()
  );

  canResolveCommunications$ = this.ngxPermissionsService.hasPermission([
    this.permConst.Case.ResolveCommunication.Write
  ]);

  followUpTodayFeature$ = this.featureService.featureFlagEnabled$(
    OrganizationFeatures.common,
    "followUpTodayFeature"
  );

  rolesToHide$ = this.featureService.configurationListValue$(
    OrganizationFeatures.communications,
    "hideFollowUpRoleFriendlyIds"
  );

  showFollowUp$ = combineLatest([
    this.currentUserRole$,
    this.rolesToHide$,
    this.followUpTodayFeature$
  ]).pipe(
    filterNullMap(),
    map(([currentUserRole, rolesToHide, featureEnabled]) => {
      if (featureEnabled) {
        if (!rolesToHide.includes(currentUserRole?.friendlyId)) return true;
      }
      return false;
    })
  );

  showCommunicationSnippets$ = combineLatest([
    this.featureService.configurationLists$(OrganizationFeatures.caseDashboard),
    this.loggedInUser$.pipe(filterNullMap())
  ]).pipe(
    map(([configList, user]: [Record<string, string[]>, User]) => {
      if (configList) {
        const showRoles = configList["showCommunicationSnippetsRoles"];
        if (Array.isArray(showRoles) && showRoles?.includes(user.selectedRoleId)) {
          return true;
        }
      }
      return false;
    })
  );

  ////////////////////   Methods

  setCommunications() {
    const caseId = this.store.selectSnapshot(CaseState.current)?.caseId as string;
    if (caseId) {
      this.getCommunications([caseId]);
    }
  }

  getCommunications(caseIds: string[]) {
    this.communicationsApiService
      .getCommunications(caseIds)
      .pipe(
        take(1),
        tap((comms: CommunicationData[]) => {
          this.store.dispatch(new CommunicationActions.SetCommunications(comms, caseIds));
        })
      )
      .subscribe();
  }

  getOperations(
    original: CommunicationData[],
    updated: CommunicationData[],
    communicationId: string
  ) {
    return {
      communicationId: communicationId,
      operations: createPatch(original, updated)
    } as CommunicationOperations;
  }

  disableCommunication(communicationId: string) {
    const caseData = this.store.selectSnapshot(CaseState.current);
    if (caseData) {
      this.store
        .dispatch(new CommunicationActions.SetCurrentCommunication(communicationId))
        .pipe(
          tap(() => {
            this.store.dispatch(
              new CommunicationActions.UpdateWorkingCopy({
                isDeleted: true
              })
            );
          }),
          tap(() => {
            this.store.dispatch(new CommunicationActions.Patch(communicationId, caseData.caseId));
          })
        )
        .subscribe();
    }
  }

  editReminder(communicationId: string, reminderDate: Date | null, isReminderEnabled: boolean) {
    const caseData = this.store.selectSnapshot(CaseState.current);
    if (caseData) {
      return this.store
        .dispatch(new CommunicationActions.SetCurrentCommunication(communicationId))
        .pipe(
          switchMap(() => {
            return this.store.dispatch(
              new CommunicationActions.UpdateWorkingCopy({
                reminderDate: reminderDate,
                isReminderEnabled: isReminderEnabled
              })
            );
          }),
          switchMap(() => {
            return this.store.dispatch(
              new CommunicationActions.Patch(communicationId, caseData.caseId)
            );
          })
        );
    }
    return EMPTY;
  }

  markResolved(communicationId: string, isResolved: boolean) {
    const caseData = this.store.selectSnapshot(CaseState.current);
    const workingCopy = this.store.selectSnapshot(CommunicationState.workingCopy);
    if (caseData && workingCopy) {
      this.store
        .dispatch(
          new CommunicationActions.UpdateWorkingCopy({
            isResolved: isResolved
          })
        )
        .pipe(
          tap(() => {
            this.store.dispatch(new CommunicationActions.Patch(communicationId, caseData.caseId));
          })
        )
        .subscribe({
          next: () => {
            if (isResolved) {
              this.notificationService.successMessage("Communication marked as resolved");
            } else {
              this.notificationService.successMessage("Communication marked as unresolved");
            }
          },
          error: () => {
            this.notificationService.errorMessage("Failed to update communication resolved status");
          }
        });
    }
  }

  showCommunicationDialog<T>(
    componentType: ComponentType<T>,
    caseData: CaseData,
    communicationId: string,
    mode: string
  ) {
    return this.matDialog
      .open(componentType, {
        width: "50vw",
        minHeight: "40vh",
        data: {
          user: this.store.selectSnapshot(ApplicationState.loggedInUser),
          caseData: caseData,
          communicationId: communicationId,
          mode: mode
        },
        panelClass: "mat-mdc-dialog-basic"
      })
      .afterClosed()
      .pipe(filter(result => result));
  }

  reply(
    communication: CommunicationData,
    caseId: string,
    reply: string,
    attachmentType?: string,
    attachmentUrl?: string
  ) {
    return this.currentUser$.pipe(
      map((user: User) => {
        return this.createReply(caseId, user, communication, reply, attachmentType, attachmentUrl);
      }),
      tap(result => {
        if (result) {
          this.notificationService.successMessage("Communication Created");
        }
      }),
      take(1)
    );
  }

  secureLink(documentUrl: string | undefined, isImage: boolean) {
    this.caseContextService.Context.pipe(
      concatMap((caseData: CaseData) => {
        if (documentUrl) {
          const blobName = documentUrl.split("/").pop();
          if (blobName) {
            const regex = /(?<caseId>.*)-(documents|images)-(?<fileName>.*)/i;
            const match = blobName.match(regex);
            const fileName = match ? match.groups?.fileName : undefined;
            if (fileName) {
              return this.fileApiService.getReadOnlySecurelink(caseData.caseId, fileName, isImage);
            }
          }
        }
        return EMPTY;
      }),
      take(1)
    ).subscribe(secureLink => window.open(secureLink, "_blank"));
  }

  /**
   * Show current message has been viewed by the current user's selected role
   * @param communicationId ID to mark
   * @returns Observable of CommunicationData | null
   */
  markCommunicationsAsRead = (communicationId: string) => {
    const userData = this.store.selectSnapshot(UserState.currentUser);
    const selectedRoleId = userData?.selectedRoleId;
    const workingCopy = this.store.selectSnapshot(CommunicationState.workingCopy);
    if (workingCopy && selectedRoleId && userData) {
      this.store
        .dispatch(
          new CommunicationActions.UpdateWorkingCopy({
            viewedBy: Array.from(new Set<string>([...workingCopy.viewedBy, selectedRoleId]))
          })
        )
        .pipe(
          take(1),
          tap(() => {
            this.store.dispatch(
              new CommunicationActions.Patch(communicationId, workingCopy.caseId)
            );
          })
        )
        .subscribe();
    }
  };

  subscribeToEvents() {
    const caseData = this.store.selectSnapshot(CaseState.current);
    if (caseData && caseData?.caseId) {
      this.eventAggregator
        .on<CommunicationEvent>(CommunicationEvent)
        .pipe(
          tap(() => {
            this.getCommunications([caseData.caseId]);
          })
        )
        .subscribe();
    }
  }

  getRoleNameById(roleId: string) {
    const roles = this.store.selectSnapshot(OrganizationState.organization)?.roles;
    return roleId ? `[${roles?.find(x => x.roleId === roleId)?.displayName}]` : "";
  }

  private createReply(
    caseId: string,
    user: User,
    communication: CommunicationData,
    reply: string,
    attachmentType?: string,
    attachmentUrl?: string
  ) {
    const newCommunication = {
      communicationType: communication.communicationType,
      communicationId: "",
      body: reply,
      isDeleted: false,
      audience: [],
      subject: `RE:${communication.subject}`,
      parentId: communication.communicationId,
      attachmentType,
      attachmentUrl,
      viewedBy: [user.selectedRoleId],
      createdBy: user.userId,
      caseId: caseId,
      resolvable: false,
      isResolved: false,
      creatorRoleId: user.selectedRoleId
    } as Partial<CommunicationData>;
    return this.store.dispatch(
      new CommunicationActions.CreateNewCommunication(newCommunication, caseId)
    );
  }
}

export const mapToViewModel = (communications: CommunicationData[]): CommunicationViewModel[] => {
  const viewModels: CommunicationViewModel[] = [];

  const parentCommunications = communications.filter(x => x.parentId === "");
  for (const communication of parentCommunications) {
    const viewModel: CommunicationViewModel = {
      ...communication,
      responses: communications.filter(x => x.parentId === communication.communicationId)
    };
    viewModels.push(viewModel);
  }

  return viewModels;
};
