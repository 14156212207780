import { Injectable, OnDestroy } from "@angular/core";
import { Actions, Select, Store } from "@ngxs/store";
import { ApplicationState } from "@vp/data-access/application";
import * as CaseActions from "@vp/data-access/case";
import { CaseApiService, CaseState } from "@vp/data-access/case";
import { CaseTypesState } from "@vp/data-access/case-types";
import { CommunicationState } from "@vp/data-access/communications";
import { GroupsState } from "@vp/data-access/groups";
import { UserState } from "@vp/data-access/users";
import { UiSchemaConfigService } from "@vp/formly/ui-schema-config";
import { JsonSchemaValidationService } from "@vp/json-schema-validation";
import {
  CaseData,
  CaseFile,
  CaseManagementData,
  CaseResponse,
  CaseType,
  FileDescriptor,
  Group,
  ICaseDocument,
  Role,
  ServiceFeesSummaryViewModel,
  User
} from "@vp/models";
import { NotificationService } from "@vp/shared/notification-service";
import { cleanData, filterNullMap } from "@vp/shared/operators";
import { SignalRApiService } from "@vp/shared/signal-r-service";
import { AppStoreService } from "@vp/shared/store/app";
import { IFilterPredicate, IPredicate, deeperCopy, mergeDeep } from "@vp/shared/utilities";
import { CommunicationData } from "libs/models/src/lib/application/communications";
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  Subject,
  combineLatest,
  of,
  throwError,
  zip
} from "rxjs";
import {
  catchError,
  concatMap,
  distinctUntilKeyChanged,
  filter,
  map,
  mergeAll,
  scan,
  shareReplay,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom
} from "rxjs/operators";
import { AnswerGroupApiService } from "./api/answer-group-api.service";
import { CaseProgressService } from "./api/case-progress.service";

export type CaseDataContext = CaseData | null;

// This needs to not be a singleton, but because of dependencies in the AccessControlService
// this has to remain for now.
// TODO: Move case type specific permissions loading out of the access control service on to an
// effect on case type load.
@Injectable({
  providedIn: "root"
})
export class CaseContextService implements OnDestroy {
  @Select(ApplicationState.loggedInUser) public loggedInUser$!: Observable<User>;
  @Select(UserState.currentUser) public currentUser$!: Observable<User>;
  @Select(CaseState.current) public caseData$!: Observable<CaseData | null>;
  @Select(GroupsState.allGroups) public allGroups$!: Observable<Group[]>;
  @Select(CaseTypesState.currentCaseType) public caseType$!: Observable<CaseType>;
  @Select(CaseTypesState.allCaseTypes) public caseTypes$!: Observable<CaseType[]>;

  private readonly destroyed$ = new Subject<void>();
  private filters$$: BehaviorSubject<Observable<IFilterPredicate>> = new BehaviorSubject<
    Observable<IFilterPredicate>
  >(of({} as IFilterPredicate));

  private excludedKeys$: Observable<IFilterPredicate[]> = this.filters$$.pipe(
    mergeAll(),
    scan((acc: IFilterPredicate[], filter: IFilterPredicate) => {
      const exists = acc.find(a => a.key === filter.key);
      if (!exists) {
        acc.push(filter);
      }
      return acc;
    }, [] as IFilterPredicate[])
  );

  constructor(
    private readonly _answerApi: AnswerGroupApiService,
    private readonly appStoreService: AppStoreService,
    private readonly caseApiService: CaseApiService,
    private readonly caseProgressService: CaseProgressService,
    private readonly notificationService: NotificationService,
    private readonly uiSchemaConfigService: UiSchemaConfigService,
    private readonly jsonSchemaValidationService: JsonSchemaValidationService,
    private readonly store: Store,
    readonly actions$: Actions,
    readonly signalRApiService: SignalRApiService
  ) {
    this.caseType$
      .pipe(filterNullMap(), distinctUntilKeyChanged("caseTypeId"), takeUntil(this.destroyed$))
      .subscribe(caseType => {
        this.uiSchemaConfigService.addScopedConfig(caseType.recordLayout, caseType.caseTypeId);
        this.jsonSchemaValidationService.loadSchema(
          caseType.recordSchema,
          caseType.caseTypeId.concat("recordSchema")
        );
        this.jsonSchemaValidationService.loadSchema(
          caseType.managementSchema,
          caseType.caseTypeId.concat("managementSchema")
        );
      });
    this.caseData$
      .pipe(
        filterNullMap(),
        distinctUntilKeyChanged("caseId"),
        switchMap((caseData: CaseData) => {
          const userData = this.store.selectSnapshot(UserState.currentUser);
          if (userData && caseData) {
            return this.signalRApiService.addToGroup(userData.userId, caseData.caseId);
          }
          return EMPTY;
        })
      )
      .subscribe();
  }

  Context: Observable<CaseData> = this.caseData$.pipe(filterNullMap());

  /**
   * This is the primary observable for subscribing to records being emitted from the context.
   * This stream is filtered of any matching keys in the excludedKeys$ observable. Which are
   * provided by the pluckRecord function.
   */
  recordDataStream = combineLatest([
    this.caseData$.pipe(
      filterNullMap(),
      map(caseData => caseData.recordData)
    ),
    this.excludedKeys$
  ]).pipe(
    map(([stream, excluded]: [Record<string, unknown>, IFilterPredicate[]]) => {
      // check for any excluded records on the stream and remove them, notifying
      // the remover via the predicate
      const excludedKey = excluded.find(e => Object.prototype.hasOwnProperty.call(stream, e.key));
      if (excludedKey) {
        excludedKey.predicate(stream);
        return null;
      }
      return stream;
    }),
    filterNullMap(),
    shareReplay({ refCount: true, bufferSize: 1 })
  );

  progress = this.caseData$.pipe(
    filterNullMap(),
    map((caseData: CaseData) => {
      return this.caseProgressService.calculateCaseProgress(caseData);
    })
  );

  /**
   * Get the communications user can view based on current role and responsibility
   */
  contextCommunications$ = combineLatest([
    this.caseData$.pipe(filterNullMap(), filterInactive()),
    this.caseType$.pipe(filterNullMap())
  ]).pipe(
    withLatestFrom(this.appStoreService.selectedRole, this.loggedInUser$),
    map(([[caseData, caseType], selectedRole, user]: [[CaseData, CaseType], Role, User]) => {
      const responsibility =
        caseData.users.find(u => u.userId === user.userId && u.roleId === selectedRole.roleId)
          ?.responsibilityFriendlyId ?? "";
      let result: string = selectedRole.friendlyId;
      if (responsibility !== "") {
        result = result + "." + responsibility;
      }
      const communications = this.store.selectSnapshot(CommunicationState.communications);
      return communications.reduce((acc: CommunicationData[], item: CommunicationData) => {
        const ctComm = caseType?.communications.find(
          comm => comm.communicationTypeId === item.communicationType?.communicationTypeId
        );

        if (!ctComm?.audience.roles || !ctComm.audience.roles.length) {
          acc.push(item);
        }

        if (
          ctComm?.audience.roles.find(
            (r: { prettyId: string | undefined }) => r.prettyId === result
          )
        ) {
          acc.push(item);
        }

        return acc;
      }, []);
    })
  );

  /**
   * Return images for the current case
   */
  contextImages = this.caseData$.pipe(
    filterNullMap(),
    filterInactive(),
    map((caseData: CaseData) => {
      const isVideo = (fileName: string): boolean => {
        return fileName.substring(fileName.indexOf(".") + 1) === "mp4";
      };
      const caseImages = caseData?.images.imageList;
      // Add new helper property for videos
      caseImages?.forEach(x => (x.isVideo = isVideo(x.fileName)));
      return caseImages;
    })
  );

  /**
   * Return DICOM studies for the current case
   * `secureLink` property is updated with environment base URL
   */
  contextDicomStudies = this.caseData$.pipe(
    filterNullMap(),
    filterInactive(),
    map((caseData: CaseData) => {
      return caseData.dicomStudies || [];
    })
  );

  /**
   * Return images for the current case
   */
  contextImagesJson = zip(
    this.caseData$.pipe(filterNullMap(), filterInactive()),
    this.caseType$.pipe(filterNullMap())
  ).pipe(
    map(([caseData, caseType]: [CaseData, CaseType]) => {
      const imageLayout = deeperCopy(caseType?.images?.imageLayout) ?? {};
      const imageSchema = deeperCopy(caseType?.images?.imageSchema) ?? {};
      if (Object.keys(imageLayout).length > 0) {
        this.uiSchemaConfigService.addScopedConfig(
          imageLayout,
          `imageLayout${caseType.caseTypeId}`
        );
      }
      return {
        caseId: caseData?.caseId,
        caseTypeId: caseType?.caseTypeId,
        data: caseData?.images?.imageData ?? {},
        schema: imageSchema,
        layout: imageLayout
      };
    })
  );

  /**
   * Return documents for the current case
   */
  contextDocuments = this.caseData$.pipe(
    filterNullMap(),
    filterInactive(),
    map((caseData: CaseData) => {
      const iconMap: Map<string, string> = new Map([
        ["jpeg", "photo_library"],
        ["jpg", "photo_library"],
        ["png", "photo_library"],
        ["pdf", "picture_as_pdf"],
        ["docx", "font_download"],
        ["doc", "font_download"],
        ["txt", "description"],
        ["zip", "archive"],
        ["csv", "description"],
        ["xlsx", "description"],
        ["json", "description"],
        ["mp4", "video_library"],
        ["mov", "video_library"]
      ]);

      let caseDocuments = caseData?.documents.documentList;

      // Add new icon property using extension
      caseDocuments = caseDocuments?.map((document: CaseFile) => {
        const extenstion = document.fileName.substring(document.fileName.lastIndexOf(".") + 1);

        return {
          fileName: document.fileName,
          displayName: document.displayName,
          fileDescription: document.fileDescription,
          url: document.url,
          extension: extenstion,
          icon: iconMap.get(extenstion),
          uploadedDateTime: document.uploadedDateTime,
          uploadedByUser: document.uploadedByUser,
          isDraft: document.isDraft
        } as ICaseDocument;
      });

      return (caseDocuments as ICaseDocument[]) ?? [];
    })
  );

  /**
   * Return documents for the current case
   */
  contextDocumentsJson = zip(
    this.caseData$.pipe(filterNullMap(), filterInactive()),
    this.caseType$.pipe(filterNullMap())
  ).pipe(
    map(([caseData, caseType]: [CaseData, CaseType]) => {
      const documentLayout = deeperCopy(caseType?.documents?.documentLayout) ?? {};
      const documentSchema = deeperCopy(caseType?.documents?.documentSchema) ?? {};
      if (Object.keys(documentLayout).length > 0) {
        this.uiSchemaConfigService.addScopedConfig(
          documentLayout,
          `documentLayout${caseType.caseTypeId}`
        );
      }
      return {
        caseId: caseData?.caseId,
        caseTypeId: caseType?.caseTypeId,
        data: caseData?.documents?.documentData ?? {},
        schema: documentSchema,
        layout: documentLayout
      };
    })
  );

  serviceFeeTotal = this.caseData$.pipe(
    filterNullMap(),
    map((caseData: CaseData) => {
      const totals: ServiceFeesSummaryViewModel = {
        totalServices: 0,
        amountDue: 0,
        amountPaid: 0,
        balanceDue: 0
      };

      if (!!caseData?.serviceFees && caseData.serviceFees?.length > 0) {
        totals.totalServices = caseData.serviceFees.length;

        let amountDue = 0;
        caseData.serviceFees.forEach(service => {
          service?.fee ? (amountDue = amountDue + service.fee) : 0;
        });

        totals.amountDue = amountDue;
        totals.balanceDue = amountDue;
      }

      if (!!caseData?.payments && caseData.payments?.length > 0) {
        let amountPaid = 0;
        caseData.payments.forEach(payment => {
          payment?.amount ? (amountPaid = amountPaid + +payment.amount) : 0;
          if (payment.refunds) {
            payment.refunds.forEach(refund => {
              refund.amount ? (amountPaid = amountPaid - +refund.amount) : 0;
            });
          }
        });

        totals.amountPaid = amountPaid;
        totals.balanceDue = totals.balanceDue - amountPaid;
      }

      return totals;
    })
  );

  contextFirstOrDefaultDocumentsDescriptor = this.caseType$.pipe(
    filterNullMap(),
    map(caseType => {
      const first = caseType.documents.documentDescriptors[0] ?? new FileDescriptor();
      return new FileDescriptor(first.fileTypes, first.required, first.recommended);
    })
  );

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * Creates an observable of keys which removes matching records from the record stream and sends the
   * record to the caller via the provided predicate.
   *
   * WARNING: Because record are removed from the stream when they are found, ONLY ONE instance of this
   * function can exist for each record being plucked. This is by design. The record can be re-emiited
   * in the predicate body if needed elsewhere.
   *
   * @param key String - The name of the key of the record to remove and send to the predicate.
   * @param predicate Function - A fucntion that is called on and with matching records in the stream
   */
  pluckRecord(key: string, predicate: IPredicate): void {
    const filter = of({
      key: key,
      predicate: predicate
    });
    this.filters$$.next(filter);
  }

  /**
   * Return the first or default document descriptor for the current case
   * @todo Extra document descriptors are ignored currently
   */

  new(): Observable<CaseData> {
    return this.caseData$.pipe(
      filterNullMap(),
      take(1),
      tap((caseData: CaseData) => {
        caseData.recordData = cleanData(caseData.recordData);
      }),
      switchMap(caseData => {
        return this.caseApiService.createCase(caseData);
      }),
      switchMap((caseData: CaseData) =>
        this.store.dispatch(new CaseActions.UpdateState(caseData)).pipe(map(() => caseData))
      )
    );
  }

  updateStatus = (statusId: string) => {
    this.store.dispatch(new CaseActions.UpdateStatus(statusId)).subscribe({
      error: () => {
        this.notificationService.errorMessage("Failed to update status");
      }
    });
  };

  updateRecordData = (recordData: Record<string, unknown>): Observable<CaseData> => {
    return this.caseData$.pipe(
      filterNullMap(),
      take(1),
      map((caseData: CaseData) => {
        return {
          ...caseData,
          recordData: cleanData(recordData)
        };
      }),
      take(1),
      tap((caseData: CaseData) => {
        this.store.dispatch(new CaseActions.UpdateState(caseData));
      }),
      take(1),
      catchError((err: any) => {
        this.notificationService.warningMessage(err);
        return throwError(err);
      })
    );
  };

  /**
   * @deprecated use case patch.
   *
   * Updates the recordData in the context and saves it.
   * This combines the updating the context and saving the data to the API
   * so that the service has more control instead of the components mangaging it.
   */
  saveRecordData = (recordData: any): Observable<CaseData> => {
    return this.updateRecordData(recordData).pipe(
      concatMap((cleanData: CaseData) =>
        this._answerApi.updateRecordData(cleanData.caseId, cleanData.recordData)
      ),
      take(1)
    );
  };

  /**
   *
   * @param managementData
   * @returns {Observable{CaseData}}
   */
  validateManagementDataAndUpdateContext = (
    managementData: Record<string, any>
  ): Observable<CaseData> => {
    if (!managementData) {
      return throwError("managementData is required");
    }
    return this.caseData$.pipe(
      filterNullMap(),
      map((caseData: CaseData) => {
        this.validateManagementData(caseData, managementData);
        return {
          ...caseData,
          management: {
            ...(caseData.management ?? {}),
            managementData: mergeDeep(caseData.management.managementData, managementData, "replace")
          }
        } as CaseData;
      }),
      take(1),
      tap((caseData: CaseData) => {
        this.store.dispatch(new CaseActions.UpdateState(caseData));
      }),
      take(1)
    );
  };

  /**
   *
   * @deprecated use SaveCaseDataAction once patch pipeline behaviors are fully functional.
   * @param managementData
   * @returns {Observable{CaseData}}
   */
  saveManagementData = (managementData: Record<string, unknown>): Observable<CaseData> => {
    if (!managementData) {
      return throwError("managementData is required");
    }
    return this.caseData$.pipe(
      filterNullMap(),
      switchMap((caseData: CaseData) => {
        this.validateManagementData(caseData, managementData);
        return this._answerApi.saveManagementData(caseData.caseId, managementData);
      }),
      take(1),
      tap((caseData: CaseData) => {
        this.store.dispatch(new CaseActions.SetState(caseData.caseId));
      }),
      take(1)
    );
  };

  /**
   *
   * @param summary
   * @returns {Observable{CaseData}}
   */
  saveSummaryData = (summary: string): Observable<CaseData> => {
    return this.caseData$.pipe(
      filterNullMap(),
      map(caseData => {
        return {
          ...caseData,
          summary: summary
        } as CaseData;
      }),
      take(1),
      tap((caseData: CaseData) => {
        this.store.dispatch(new CaseActions.Patch(caseData));
      }),
      take(1)
    );
  };

  updateSummaryViewNotesContext = (summaryViewNotes: string): Observable<CaseData> => {
    if (!summaryViewNotes) {
      return throwError("summaryViewNotes is required");
    }
    return this.caseData$.pipe(
      filterNullMap(),
      map(caseData => {
        return {
          ...caseData,
          summaryViewNotes: summaryViewNotes
        } as CaseData;
      }),
      take(1),
      tap(caseData => {
        this.store.dispatch(new CaseActions.UpdateState(caseData));
      }),
      take(1)
    );
  };

  saveSummaryViewNotesData = (summaryViewNotes: string): Observable<CaseData> => {
    return this.caseData$.pipe(
      filterNullMap(),
      map(caseData => {
        return {
          ...caseData,
          summaryViewNotes: summaryViewNotes
        } as CaseData;
      }),
      take(1),
      tap(caseData => {
        this.store.dispatch(new CaseActions.Patch(caseData));
      }),
      take(1)
    );
  };

  updateManagementDataContext = (caseManagementData: CaseManagementData): Observable<CaseData> => {
    if (!caseManagementData) {
      return throwError("caseManagementData is required");
    }
    return this.caseData$.pipe(
      filterNullMap(),
      map(caseData => {
        return {
          ...caseData,
          management: caseManagementData
        } as CaseData;
      }),
      take(1),
      tap(caseData => {
        this.store.dispatch(new CaseActions.UpdateState(caseData));
      }),
      take(1)
    );
  };

  updateSummaryDataContext = (summaryData: string): Observable<CaseData> => {
    if (!summaryData) {
      return throwError("summaryData is required");
    }
    return this.caseData$.pipe(
      filterNullMap(),
      map(caseData => {
        return {
          ...caseData,
          summary: summaryData
        } as CaseData;
      }),
      take(1),
      tap(caseData => {
        this.store.dispatch(new CaseActions.UpdateState(caseData));
      }),
      take(1)
    );
  };

  generateResponsePdfPreview = (caseResponse: Partial<CaseResponse>) => {
    return this.caseData$.pipe(
      filterNullMap(),
      take(1),
      concatMap(caseData => {
        return this.caseApiService.generateResponsePdfPreview(caseData.caseId, caseResponse);
      })
    );
  };

  /**
   *
   * @param caseResponse {CaseResponse}
   * @param fileName {string}
   * @returns {boolean} indicates whether or not the action completed successfully
   */
  generateResponsePdf = (caseResponse: CaseResponse, fileName?: string) => {
    return this.caseData$.pipe(
      filterNullMap(),
      concatMap(caseData => {
        return combineLatest([
          this.caseApiService.generateResponsePdf(caseData.caseId, caseResponse, fileName),
          of(caseResponse)
        ]);
      }),
      take(1),
      switchMap(([caseFile, caseResponse]: [CaseFile, CaseResponse]) =>
        this.store.dispatch(new CaseActions.UpdateResponse(caseFile, caseResponse))
      )
    );
  };

  /**
   * @deprecated use patch
   * @param caseResponse
   * @returns
   */
  createResponse = (caseResponse: Partial<CaseResponse>) => {
    return this.caseData$.pipe(
      filterNullMap(),
      take(1),
      switchMap(caseData =>
        zip(of(caseData), this.caseApiService.saveResponse(caseData.caseId, caseResponse))
      ),
      switchMap(([caseData, response]: [CaseData, CaseResponse]) => {
        if (response) {
          return zip(of(response), this.store.dispatch(new CaseActions.SetState(caseData.caseId)));
        }
        return throwError("saveResponse");
      }),
      map(([response]) => response)
    );
  };

  /**
   * @deprecated use patch
   * @param responseId
   */
  deleteResponse = (responseId: string): void => {
    /**
     * TODO: Not a huge fan of this pattern but the response code in the api
     * is doing a bit more than what can be accomidated with a patch so we update
     * and refresh until we can refactor the api call. */
    this.caseData$
      .pipe(
        filterNullMap(),
        switchMap(caseData => this.caseApiService.daleteResponse(caseData.caseId, responseId)),
        take(1),
        tap((deleted: boolean) => {
          if (deleted) {
            this.store.dispatch(new CaseActions.RefreshCurrent());
          }
        })
      )
      .subscribe();
  };

  /**
   * @deprecated use patch
   * @param caseResponse
   * @returns
   */
  updateResponse = (caseResponse: CaseResponse) => {
    /**
     * TODO: Not a huge fan of this pattern but the response code in the api
     * is doing a bit more than what can be accomidated with a patch so we update
     * and refresh until we can refactor the api call. */
    return this.caseData$.pipe(
      filterNullMap(),
      switchMap(caseData =>
        combineLatest([
          of(caseData),
          this.caseApiService.updateResponse(caseData.caseId, caseResponse)
        ])
      ),
      take(1),
      switchMap(([caseData, caseResponse]: [CaseData, CaseResponse]) => {
        if (!!caseResponse && !!caseData.caseId) {
          return this.store.dispatch(new CaseActions.SetState(caseData.caseId));
        }
        return throwError("Error updating response");
      })
    );
  };

  /**
   *  Validate the managementData against the schema to determine if it can be added to the context.
   */
  private validateManagementData = (caseData: CaseData, managementData: Record<string, any>) => {
    const validationResult = this.jsonSchemaValidationService.validateData(
      caseData.caseType.caseTypeId.concat("managementSchema"),
      managementData
    );
    if (!validationResult.isValid) {
      throw Error(validationResult.errorsText);
    }
  };
}

const filterInactive = () => {
  return (source$: Observable<CaseData | null | undefined>) =>
    source$.pipe(
      filterNullMap(),
      filter((caseData: CaseData) => {
        return caseData.active === true;
      })
    );
};
